import React, { Component } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowUp,
  faArrowDown,
  faMinus,
} from "@fortawesome/free-solid-svg-icons"

import chartsStyles from "./charts.module.scss"
import { initializeThermometer, updateValues } from "./thermometer-d3"

const title = "Temperatura"
const image = "/img/temperature.jpg"

class ThermometerChart extends Component {
  constructor(props) {
    super(props)
    this.tempTL = props.data.tempTL
    this.tempTH = props.data.tempTH
    this.temp = props.data.temp
    this.myRef = React.createRef()
  }

  componentDidMount() {
    initializeThermometer(
      this.myRef.current,
      this.tempTL,
      this.tempTH,
      this.temp
    )
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.data.tempTL !== prevProps.data.tempTL ||
      this.props.data.tempTH !== prevProps.data.tempTH ||
      this.props.data.temp !== prevProps.data.temp
    ) {
      console.log("update")
      updateValues(this.props.tempTL, this.props.tempTH, this.props.temp)
    }
  }

  render() {
    let tempTrendValue = Number(this.props.data.temptrend)

    return (
      <div
        className={`scroll-target card shadow mt-2 mb-3 ${chartsStyles.h100}`}
      >
        <div className={chartsStyles.imgContainer}>
          <img src={image} className="card-img-top" alt={title} />
          <h3
            className={`card-title mb-0 p-3 ${chartsStyles.cardTitleOverImg}`}
          >
            {title}
          </h3>
        </div>
        <div className="card-body">
          <div className={chartsStyles.rtDataContainer} ref={this.myRef}>
            <div className={`small ${chartsStyles.rtData}`}>
              <div>
                Corrente:{" "}
                {`${this.props.data.temp}°${this.props.data.tempunit} `}
                <span title={this.props.data.temptrend}>
                  {tempTrendValue > 0 && (
                    <FontAwesomeIcon
                      className={chartsStyles.trendUp}
                      icon={faArrowUp}
                    />
                  )}
                  {tempTrendValue < 0 && (
                    <FontAwesomeIcon
                      className={chartsStyles.trendDown}
                      icon={faArrowDown}
                    />
                  )}
                  {tempTrendValue === 0 && (
                    <FontAwesomeIcon
                      className={chartsStyles.trendSame}
                      icon={faMinus}
                    />
                  )}
                </span>
              </div>
              <div>
                Percepita:{" "}
                {`${this.props.data.wchill}°${this.props.data.tempunit}`}
              </div>
              <div>
                Apparente:{" "}
                {`${this.props.data.apptemp}°${this.props.data.tempunit}`}
              </div>
              <div>
                Massima:{" "}
                {`${this.props.data.tempTH}°${this.props.data.tempunit} @ ${this.props.data.TtempTH}`}
              </div>
              <div>
                Minima:{" "}
                {`${this.props.data.tempTL}°${this.props.data.tempunit} @ ${this.props.data.TtempTL}`}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ThermometerChart
