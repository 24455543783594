const currentDataFile = "/Cumulus/customrealtimegauges.txt"
export const dataRefreshInterval = 5 * 1000 // 5 seconds

export const getCurrentData = async abortController => {
  try {
    let response = await fetch(currentDataFile, {
      signal: abortController.signal,
    })
    let currentData = await response.json()
    return currentData
  } catch (e) {
    if (e.name === "AbortError") {
      console.log("Current data fetch request aborted")
    } else {
      console.error("Error getting current data: ", e)
    }
    return null
  }
}
