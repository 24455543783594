import React, { Component } from "react"
import HighchartsReact from "highcharts-react-official"
import Highcharts from "highcharts"
import HcMore from "highcharts/highcharts-more"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowUp,
  faArrowDown,
  faMinus,
} from "@fortawesome/free-solid-svg-icons"

import chartsStyles from "./charts.module.scss"
import { getHumidityAndPressureChartOptions } from "./charts"

const title = "Umidità e Pressione"
const image = "/img/pressure.jpg"

class HumidityAndPressureChart extends Component {
  constructor(props) {
    super(props)
    if (typeof window !== `undefined`) {
      HcMore(Highcharts)
    }
    this.chartOptions = getHumidityAndPressureChartOptions()
    this.chartRef = React.createRef()
  }

  componentDidMount() {
    this.updateData()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.press !== prevProps.press ||
      this.props.hum !== prevProps.hum
    ) {
      this.updateData()
    }
  }

  updateData() {
    let chartObj = this.chartRef.current.chart
    const pressure = Number(this.props.data.press)
    const humidity = Number(this.props.data.hum)
    var point = chartObj.series[0].points[0]
    point.update(humidity)
    var point2 = chartObj.series[1].points[0]
    point2.update(pressure)
  }

  render() {
    let presstrendValue = Number(this.props.data.presstrendval)

    return (
      <div
        className={`scroll-target card shadow mt-2 mb-3 ${chartsStyles.h100}`}
      >
        <div className={chartsStyles.imgContainer}>
          <img src={image} className="card-img-top" alt={title} />
          <h3
            className={`card-title mb-0 p-3 ${chartsStyles.cardTitleOverImg}`}
          >
            {title}
          </h3>
        </div>
        <div className="card-body">
          <div className={chartsStyles.rtDataContainer}>
            <div className={chartsStyles.rtChart}>
              <HighchartsReact
                hHighcharts={Highcharts}
                options={this.chartOptions}
                ref={this.chartRef}
                containerProps={{ style: { height: "100%" } }}
              />
            </div>
            <div className={`small ${chartsStyles.rtData}`}>
              <div>
                Pressione:{" "}
                {`${this.props.data.press} ${this.props.data.pressunit} `}
                <span title={this.props.data.presstrendval}>
                  {presstrendValue > 0 && (
                    <FontAwesomeIcon
                      className={chartsStyles.trendUp}
                      icon={faArrowUp}
                    />
                  )}
                  {presstrendValue < 0 && (
                    <FontAwesomeIcon
                      className={chartsStyles.trendDown}
                      icon={faArrowDown}
                    />
                  )}
                  {presstrendValue === 0 && (
                    <FontAwesomeIcon
                      className={chartsStyles.trendSame}
                      icon={faMinus}
                    />
                  )}
                </span>
              </div>
              <div>
                Massima:{" "}
                {`${this.props.data.pressTH} ${this.props.data.pressunit} @ ${this.props.data.TpressTH}`}
              </div>
              <div>
                Minima:{" "}
                {`${this.props.data.pressTL} ${this.props.data.pressunit} @ ${this.props.data.TpressTL}`}
              </div>
              <div>Umidità: {`${this.props.data.hum}%`}</div>
              <div>
                Massima:{" "}
                {`${this.props.data.humTH}% @ ${this.props.data.ThumTH}`}
              </div>
              <div>
                Minima:{" "}
                {`${this.props.data.humTL}% @ ${this.props.data.ThumTL}`}
              </div>
              <div>
                Punto di rugiada:{" "}
                {`${this.props.data.dew}°${this.props.data.tempunit}`}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default HumidityAndPressureChart
