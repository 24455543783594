import React, { Component } from "react"
import HighchartsReact from "highcharts-react-official"
import Highcharts from "highcharts"
import HcMore from "highcharts/highcharts-more"

import chartsStyles from "./charts.module.scss"
import { scalaBeaufort, getBeaufortGrade, getWindChartOptions } from "./charts"

const title = "Vento"
const image = "/img/wind.jpg"

class WindChart extends Component {
  constructor(props) {
    super(props)
    if (typeof window !== `undefined`) {
      HcMore(Highcharts)
    }
    this.chartOptions = getWindChartOptions()
    this.chartRef = React.createRef()
  }

  componentDidMount() {
    this.updateData()
  }

  componentDidUpdate(prevProps) {
    if (this.props.bearing !== prevProps.bearing) {
      this.updateData()
    }
  }

  updateData() {
    let chartObj = this.chartRef.current.chart
    const windSpeed = Number(this.props.data.bearing)
    var point = chartObj.series[0].points[0]
    point.update(windSpeed)
    var point2 = chartObj.series[1].points[0]
    point2.update(windSpeed)
  }

  render() {
    let beaufortGrade = getBeaufortGrade(Number(this.props.data.wlatest))

    return (
      <div
        className={`scroll-target card shadow mt-2 mb-3 ${chartsStyles.h100}`}
      >
        <div className={chartsStyles.imgContainer}>
          <img src={image} className="card-img-top" alt={title} />
          <h3
            className={`card-title mb-0 p-3 ${chartsStyles.cardTitleOverImg}`}
          >
            {title}
          </h3>
        </div>
        <div className="card-body">
          <div className={chartsStyles.rtDataContainer}>
            <div className={chartsStyles.rtChart}>
              <HighchartsReact
                hHighcharts={Highcharts}
                options={this.chartOptions}
                ref={this.chartRef}
                containerProps={{ style: { height: "100%" } }}
              />
            </div>
            <div className={`small ${chartsStyles.rtData}`}>
              <div>Direzione: {this.props.data.currentwdir}</div>
              <div>
                Velocità:{" "}
                {`${this.props.data.wlatest} ${this.props.data.windunit}`}
              </div>
              <div>
                Velocità media:{" "}
                {`${this.props.data.wspeed} ${this.props.data.windunit}`}
              </div>
              <div>
                Velocità massima:{" "}
                {`${this.props.data.windTM} ${this.props.data.windunit} @ ${this.props.data.TwindTM}`}
              </div>
              <div>
                Raffica:{" "}
                {`${this.props.data.wgust} ${this.props.data.windunit}`}
              </div>
              <div>
                Raffica massima:{" "}
                {`${this.props.data.wgustTM} ${this.props.data.windunit} @ ${this.props.data.TwgustTM}`}
              </div>
              {beaufortGrade !== -1 && (
                <div>{`${beaufortGrade} Bft - ${scalaBeaufort[beaufortGrade].descrizione}`}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default WindChart
