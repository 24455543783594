export const scalaBeaufort = [
  { max: 1, descrizione: "Calma" },
  { max: 6, descrizione: "Bava di vento" },
  { max: 11, descrizione: "Brezza leggera" },
  { max: 19, descrizione: "Brezza tesa" },
  { max: 29, descrizione: "Vento moderato" },
  { max: 39, descrizione: "Vento teso" },
  { max: 50, descrizione: "Vento fresco" },
  { max: 62, descrizione: "Vento forte" },
  { max: 75, descrizione: "Burrasca" },
  { max: 87, descrizione: "Burrasca forte" },
  { max: 102, descrizione: "Tempesta" },
  { max: 117, descrizione: "Tempesta violenta" },
  { max: 999999, descrizione: "Uragano" },
]

export const getBeaufortGrade = speed => {
  for (let i = 0; i < scalaBeaufort.length; i++) {
    if (speed < scalaBeaufort[i].max) {
      return i
    }
  }
  return -1
}

export const getWindChartOptions = () => {
  let options = {
    chart: {
      type: "gauge",
      spacingBottom: 0,
      spacingTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    tooltip: { enabled: false },
    pane: {
      startAngle: 0,
      endAngle: 360,
      background: [
        {
          // default background
        },
        {
          borderWidth: 1,
          outerRadius: "50%",
        },
        {
          backgroundColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: [
              [0, "#333"],
              [1, "#FFF"],
            ],
          },
          borderWidth: 4,
          outerRadius: "10%",
        },
        {
          backgroundColor: "rgb(153, 153, 153)",
          borderWidth: 2,
          outerRadius: "103%",
          innerRadius: "102%",
        },
      ],
    },
    // the value axis
    yAxis: [
      {
        title: {
          text: "",
        },
        min: 0,
        max: 360,
        lineColor: "#333",
        offset: -10,
        tickInterval: 20,
        tickWidth: 2,
        tickPosition: "outside",
        tickLength: 10,
        tickColor: "#333",
        minorTickInterval: 5,
        minorTickWidth: 1,
        minorTickLength: 10,
        minorTickPosition: "outside",
        minorTickColor: "#666",
        labels: {
          zIndex: 1,
          step: 1,
          distance: -12,
          rotation: "auto",
        },
      },
      {
        title: {
          text: "",
        },
        type: "category",
        categories: ["N", "NE", "E", "SE", "S", "SW", "W", "NW", "N"],
        min: 0,
        max: 8,
        lineColor: "#ddd",
        offset: -50,
        tickInterval: 1,
        tickWidth: 1,
        tickPosition: "outside",
        tickLength: 40, // =50-10
        tickColor: "#ddd",
        minorTickInterval: 1,
        minorTickWidth: 0,
        minorTickLength: 50,
        minorTickPosition: "inside",
        minorTickColor: "#0f0",
        labels: {
          zIndex: 1,
          step: 1,
          distance: 2,
          rotation: "auto",
        },
        endOnTick: true,
      },
      {
        type: "number",
        title: {
          text: "",
        },
        labels: {
          enabled: false,
        },
        min: 0,
        max: 12,
        lineColor: "#ddd",
        offset: -50,
        tickInterval: 10,
        tickWidth: 1,
        tickPosition: "inside",
        tickLength: 45,
        tickColor: "#ddd",
        minorTickWidth: 0,
        endOnTick: false,
      },
    ],
    plotOptions: {
      series: {
        dataLabels: {
          format: "{y}°",
          borderWidth: 0,
          //y: 35
        },
      },
    },
    series: [
      {
        name: "Direzione",
        yAxis: 0,
        data: [0],
        dial: {
          radius: "88%",
          baseWidth: 10,
          baseLength: "0%",
          rearLength: 0,
          borderWidth: 1,
          borderColor: "#9A0000",
          backgroundColor: "#CC0000",
        },
        tooltip: {
          valueSuffix: "°",
        },
        enableMouseTracking: false,
      },
      {
        data: [0],
        yAxis: 0,
        dial: {
          radius: "-88%",
          baseWidth: 10,
          baseLength: "0%",
          rearLength: 0,
          borderWidth: 1,
          borderColor: "#1B4684",
          backgroundColor: "#3465A4",
        },
        enableMouseTracking: false,
      },
    ],
  }
  return options
}

export const getRainChartOptions = () => {
  let options = {
    chart: {
      type: "column",
      alignTicks: false,
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
      spacingBottom: 0,
      spacingLeft: 0,
      spacingRight: 0,
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: ["Oggi"],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        //text: "Precipitazioni (mm)"
        text: null,
      },
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Pioggia",
        showInLegend: false,
        data: [0],
        tooltip: {
          valueSuffix: " mm",
        },
        dataLabels: {
          enabled: true,
          crop: false,
          overflow: "none",
          format: "{y} mm",
          color: "rgb(124, 181, 236)",
          style: {
            textOutline: false,
          },
        },
        enableMouseTracking: false,
      },
    ],
  }
  return options
}

export const getHumidityAndPressureChartOptions = () => {
  let options = {
    chart: {
      type: "gauge",
      alignTicks: false,
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
      spacingBottom: 0,
      spacingTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    pane: {
      startAngle: -150,
      endAngle: 150,
      background: [
        {
          // default background
        },
        {
          borderWidth: 1,
          outerRadius: "43%",
        },
        {
          backgroundColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: [
              [0, "#333"],
              [1, "#FFF"],
            ],
          },
          borderWidth: 4,
          outerRadius: "10%",
        },
        {
          backgroundColor: "rgb(153, 153, 153)",
          borderWidth: 2,
          outerRadius: "103%",
          innerRadius: "102%",
        },
      ],
    },

    yAxis: [
      {
        min: 0,
        max: 100,
        lineColor: "#3465A4",
        tickColor: "#3465A4",
        minorTickColor: "#3465A4",
        offset: -25,
        lineWidth: 2,
        labels: {
          zIndex: 1,
          distance: -16,
          rotation: "auto",
        },
        tickLength: 5,
        minorTickLength: 5,
        endOnTick: false,
      },
      {
        min: 910,
        max: 1070,
        tickPosition: "outside",
        lineColor: "#CC0000",
        lineWidth: 2,
        minorTickPosition: "outside",
        tickColor: "#CC0000",
        minorTickColor: "#CC0000",
        tickLength: 5,
        minorTickLength: 5,
        labels: {
          zIndex: 1,
          distance: 8,
          rotation: "auto",
        },
        offset: -20,
        endOnTick: false,
      },
    ],
    plotOptions: {
      series: {
        dataLabels: {
          formatter: function () {
            let chart = this.series.chart
            let humidity = chart.series[0].data[0].y
            let pressure = chart.series[1].data[0].y
            return (
              '<div style="text-align: center"><span style="color:#3465A4">' +
              humidity +
              " %</span><br/>" +
              '<span style="color:#CC0000">' +
              pressure +
              " hpa</span></div>"
            )
          },
          y: 40,
          borderWidth: 0,
          align: "center",
          useHTML: true,
        },
      },
    },
    series: [
      {
        name: "Umidità",
        color: "#3465A4",
        yAxis: 0,
        data: [0],
        dial: {
          radius: "58%",
          baseWidth: 10,
          baseLength: "0%",
          rearLength: 0,
          borderWidth: 1,
          borderColor: "#1B4684",
          backgroundColor: "#3465A4",
        },
        tooltip: {
          valueSuffix: "%",
        },
        enableMouseTracking: false,
      },
      {
        name: "Pressione",
        color: "#CC0000",
        yAxis: 1,
        data: [0],
        dial: {
          radius: "80%",
          baseWidth: 10,
          baseLength: "0%",
          rearLength: 0,
          borderWidth: 1,
          borderColor: "#9A0000",
          backgroundColor: "#CC0000",
        },
        tooltip: {
          valueSuffix: " hpa",
        },
        enableMouseTracking: false,
      },
    ],
  }
  return options
}
