import React, { Component } from "react"
import moment from "moment"
import "moment/locale/it"

const dateFormat = "DD/MM/YYYY"
const timeFormat = "HH:mm:ss"
const cumulusDateFormat = "YYYY,MM,DD,HH,mm,ss"
const highlightTimeout = 2 * 1000

class LastRefreshTimer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      timestamp: props.timestamp,
      seconds: 0,
      hasNewData: true,
    }
  }

  componentDidMount() {
    this.intervalID = setInterval(() => this.tick(), 1000)
  }

  componentDidUpdate(prevProps) {
    if (this.props.timestamp !== prevProps.timestamp) {
      this.setState({
        timestamp: this.props.timestamp,
        seconds: 0,
        hasNewData: true,
      })
      clearTimeout(this.timeoutID)
      this.timeoutID = setTimeout(
        () => this.setState({ hasNewData: false }),
        highlightTimeout
      )
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID)
    clearTimeout(this.timeoutID)
  }

  tick() {
    this.setState({
      seconds: this.state.seconds + 1,
    })
  }

  render() {
    return (
      <p>
        {this.state.timestamp ? (
          <span>
            <span className={this.state.hasNewData ? "text-success" : ""}>
              Dati ricevuti:
            </span>{" "}
            {moment
              .utc(this.state.timestamp, cumulusDateFormat)
              .local()
              .format(dateFormat)}{" "}
            ore{" "}
            {moment
              .utc(this.state.timestamp, cumulusDateFormat)
              .local()
              .format(timeFormat)}
          </span>
        ) : (
          <span>Nessun dato ricevuto...</span>
        )}
        <br />
        Ultimo aggiornamento: {this.state.seconds} sec fa
      </p>
    )
  }
}

export default LastRefreshTimer
