import React, { Component } from "react"
import HighchartsReact from "highcharts-react-official"
import Highcharts from "highcharts"
import moment from "moment"
import "moment/locale/it"

import chartsStyles from "./charts.module.scss"
import { getRainChartOptions } from "./charts"

const title = "Pioggia"
const image = "/img/rain.jpg"
const dateTimeFormat = "DD/MM/YYYY [@] HH:mm"

class RainChart extends Component {
  constructor(props) {
    super(props)
    this.chartOptions = getRainChartOptions()
    this.chartRef = React.createRef()
  }

  componentDidMount() {
    this.updateData()
  }

  componentDidUpdate(prevProps) {
    if (this.props.rfall !== prevProps.rfall) {
      this.updateData()
    }
  }

  updateData() {
    let chartObj = this.chartRef.current.chart
    const rfall = Number(this.props.data.rfall)
    var point = chartObj.series[0].points[0]
    point.update(rfall)
  }

  render() {
    return (
      <div
        className={`scroll-target card shadow mt-2 mb-3 ${chartsStyles.h100}`}
      >
        <div className={chartsStyles.imgContainer}>
          <img src={image} className="card-img-top" alt={title} />
          <h3
            className={`card-title mb-0 p-3 ${chartsStyles.cardTitleOverImg}`}
          >
            {title}
          </h3>
        </div>
        <div className="card-body">
          <div className={chartsStyles.rtDataContainer}>
            <div className={chartsStyles.rtChart}>
              <HighchartsReact
                hHighcharts={Highcharts}
                options={this.chartOptions}
                ref={this.chartRef}
                containerProps={{ style: { height: "100%" } }}
              />
            </div>
            <div className={`small ${chartsStyles.rtData}`}>
              <div>
                Pioggia odierna:{" "}
                {`${this.props.data.rfall} ${this.props.data.rainunit}`}
              </div>
              <div>
                Intensità oraria:{" "}
                {`${this.props.data.rrate} ${this.props.data.rainunit}/h`}
              </div>
              <div>
                Intensità massima:{" "}
                {`${this.props.data.rrateTM} ${this.props.data.rainunit}/h @ ${this.props.data.TrrateTM}`}
              </div>
              <div>
                Pioggia ieri:{" "}
                {`${this.props.data.rfallY} ${this.props.data.rainunit}`}
              </div>
              <div>
                Questo mese:{" "}
                {`${this.props.data.rmonth} ${this.props.data.rainunit}`}
              </div>
              <div>
                Quest'anno:{" "}
                {`${this.props.data.ryear} ${this.props.data.rainunit}`}
              </div>
              <div>
                Ultima precipitazione:{" "}
                {moment(this.props.data.LastRainTipISO).format(dateTimeFormat)}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default RainChart
