import React, { useState, useEffect } from "react"
import $ from "jquery"

import WebcamModal from "../modals/webcam"
import webcamStyles from "./webcam.module.scss"

const webcamImageUrl = "/webcam/current.jpg?refreshtime="
const webcamRefreshInterval = 15 * 60 * 1000 // 15 minutes

const Webcam = () => {
  const [webcamImageSrc, setWebcamImageSrc] = useState(
    webcamImageUrl + new Date().getTime()
  )

  useEffect(() => {
    let interval = setInterval(async () => {
      console.log("Refreshing webcam image...")
      setWebcamImageSrc(webcamImageUrl + new Date().getTime())
    }, webcamRefreshInterval)
    return () => clearInterval(interval)
  }, [])

  return (
    <div>
      <a
        href="/#"
        onClick={e => {
          e.preventDefault()
          $("#webcamModal").modal("show")
        }}
      >
        <img
          id="webcamImage"
          className={`shadow ${webcamStyles.webcamImage}`}
          src={webcamImageSrc}
          alt="Webcam TaladaMeteo"
          title="Immagine aggiornata ogni 30 minuti"
        />
      </a>
      <WebcamModal id="webcamModal" imageSrc={webcamImageSrc} />
    </div>
  )
}

export default Webcam
