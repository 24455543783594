import React, { useState, useEffect } from "react"
import loadable from "@loadable/component"

import Layout from "../components/layout"
import Head from "../components/head"
import LastRefreshTimer from "../components/lastRefreshTimer"
import ThermometerChart from "../charts/thermometerChart"
import WindChart from "../charts/windChart"
import RainChart from "../charts/rainChart"
import HumidityAndPressureChart from "../charts/humidityAndPressureChart"
import Webcam from "../components/webcam"
import { dataRefreshInterval, getCurrentData } from "../scripts/home"
import PaypalDonateBtn from "../components/paypalDonateBtn"
import { updateScrollCaret } from "../components/stepScroller"
const ScrollReveal = loadable(() => import("../components/scrollReveal"))

const IndexPage = () => {
  const [timestamp, setTimestamp] = useState(null)
  const [currentData, setCurrentData] = useState(null)

  useEffect(() => {
    const abortCont = new AbortController()
    updateCurrentData(abortCont)
    let interval = setInterval(async () => {
      updateCurrentData(abortCont)
    }, dataRefreshInterval)
    return () => {
      clearInterval(interval)
      abortCont.abort()
    }
  }, [])

  const updateCurrentData = async abortCont => {
    let data = await getCurrentData(abortCont)
    if (data) {
      setCurrentData(data)
      setTimestamp(data.timeUTC)
    }
    updateScrollCaret()
  }

  return (
    <Layout>
      <Head />
      <section id="datiAttuali">
        <div className="container pt-3 text-center">
          <ScrollReveal options={{ duration: 1000, origin: "top" }}>
            <h1>Dati in tempo reale</h1>
            <div>
              <LastRefreshTimer timestamp={timestamp} />
            </div>
          </ScrollReveal>
          <ScrollReveal options={{ duration: 2000, origin: "left" }}>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
              <div className="col pb-3">
                {currentData && <ThermometerChart data={currentData} />}
              </div>
              <div className="col pb-3">
                {currentData && <WindChart data={currentData} />}
              </div>
              <div className="col pb-3">
                {currentData && <RainChart data={currentData} />}
              </div>
              <div className="col pb-3">
                {currentData && <HumidityAndPressureChart data={currentData} />}
              </div>
            </div>
          </ScrollReveal>
        </div>
      </section>
      <ScrollReveal
        options={{ duration: 2000, origin: "bottom", viewFactor: 0.2 }}
      >
        <section id="webcam" className="bg-light">
          <hr />
          <div className="container text-center">
            <h1>Webcam</h1>
            <Webcam />
          </div>
          <hr className="mb-0" />
        </section>
      </ScrollReveal>
      <section id="donazioni">
        <div className="container pt-3 text-center">
          <ScrollReveal
            options={{ duration: 2000, origin: "bottom", viewFactor: 0.2 }}
          >
            <h1>Donazioni</h1>
            <p>
              Aiutaci a mantenere online ma sopratutto a sostituire la stazione
              meteo che ultimamente da parecchi problemi!
            </p>
            <div className="mb-3">
              <PaypalDonateBtn />
            </div>
          </ScrollReveal>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
