import * as d3 from "d3"

const width = 180,
  height = 180,
  bottomY = height - 5,
  topY = 5,
  bulbRadius = 20,
  tubeWidth = 21.5,
  tubeBorderWidth = 1,
  mercuryColor = "rgb(124, 181, 236)",
  innerBulbColor = "rgb(200, 230, 255)",
  tubeBorderColor = "#999999"

let minTemp = 0,
  maxTemp = 0,
  currentTemp = 0

const bulb_cy = bottomY - bulbRadius,
  bulb_cx = width / 2,
  top_cy = topY + tubeWidth / 2

let scale
let domain
// Scale step size
const step = 5

let svg
export const initializeThermometer = (nodeRef, min, max, current) => {
  svg = d3
    .select(nodeRef)
    .insert("svg", ":first-child")
    .attr("width", width)
    .attr("height", height)

  const defs = svg.append("defs")

  // Define the radial gradient for the bulb fill colour
  const bulbGradient = defs
    .append("radialGradient")
    .attr("id", "bulbGradient")
    .attr("cx", "50%")
    .attr("cy", "50%")
    .attr("r", "50%")
    .attr("fx", "50%")
    .attr("fy", "50%")

  bulbGradient
    .append("stop")
    .attr("offset", "0%")
    .style("stop-color", innerBulbColor)

  bulbGradient
    .append("stop")
    .attr("offset", "90%")
    .style("stop-color", mercuryColor)

  // Circle element for rounded tube top
  svg
    .append("circle")
    .attr("r", tubeWidth / 2)
    .attr("cx", width / 2)
    .attr("cy", top_cy)
    .style("fill", "#FFFFFF")
    .style("stroke", tubeBorderColor)
    .style("stroke-width", tubeBorderWidth + "px")

  // Rect element for tube
  svg
    .append("rect")
    .attr("x", width / 2 - tubeWidth / 2)
    .attr("y", top_cy)
    .attr("height", bulb_cy - top_cy)
    .attr("width", tubeWidth)
    .style("shape-rendering", "crispEdges")
    .style("fill", "#FFFFFF")
    .style("stroke", tubeBorderColor)
    .style("stroke-width", tubeBorderWidth + "px")

  // White fill for rounded tube top circle element
  // to hide the border at the top of the tube rect element
  svg
    .append("circle")
    .attr("r", tubeWidth / 2 - tubeBorderWidth / 2)
    .attr("cx", width / 2)
    .attr("cy", top_cy)
    .style("fill", "#FFFFFF")
    .style("stroke", "none")

  // Main bulb of thermometer (empty), white fill
  svg
    .append("circle")
    .attr("r", bulbRadius)
    .attr("cx", bulb_cx)
    .attr("cy", bulb_cy)
    .style("fill", "#FFFFFF")
    .style("stroke", tubeBorderColor)
    .style("stroke-width", tubeBorderWidth + "px")

  // Rect element for tube fill colour
  svg
    .append("rect")
    .attr("x", width / 2 - (tubeWidth - tubeBorderWidth) / 2)
    .attr("y", top_cy)
    .attr("height", bulb_cy - top_cy)
    .attr("width", tubeWidth - tubeBorderWidth)
    .style("shape-rendering", "crispEdges")
    .style("fill", "#FFFFFF")
    .style("stroke", "none")

  initializeThermometerData(min, max, current)
}

const initializeThermometerData = (min, max, current) => {
  minTemp = min
  maxTemp = max
  currentTemp = current

  // Determine a suitable range of the temperature scale
  domain = [step * Math.floor(minTemp / step), step * Math.ceil(maxTemp / step)]

  if (minTemp - domain[0] < 0.66 * step) domain[0] -= step

  if (domain[1] - maxTemp < 0.66 * step) domain[1] += step

  // D3 scale object
  scale = d3
    .scaleLinear()
    .range([bulb_cy - bulbRadius / 2 - 8.5, top_cy])
    .domain(domain)

  // Max and min temperature lines
  ;[minTemp, maxTemp].forEach(function (t) {
    let isMax = t === maxTemp,
      label = isMax ? "max" : "min",
      textCol = isMax ? "rgb(230, 0, 0)" : "rgb(0, 0, 230)",
      textOffset = isMax ? -4 : 4

    svg
      .append("line")
      .attr("id", label + "Line")
      .attr("x1", width / 2 - tubeWidth / 2)
      .attr("x2", width / 2 + tubeWidth / 2 + 22)
      .attr("y1", scale(t))
      .attr("y2", scale(t))
      .style("stroke", tubeBorderColor)
      .style("stroke-width", "1px")
      .style("shape-rendering", "crispEdges")

    svg
      .append("text")
      .attr("id", label + "Label")
      .attr("x", width / 2 + tubeWidth / 2 + 2)
      .attr("y", scale(t) + textOffset)
      .attr("dy", isMax ? null : "0.75em")
      .text(`${label} ${t}`)
      .style("fill", textCol)
      .style("font-size", "11px")
  })

  let tubeFill_bottom = bulb_cy,
    tubeFill_top = scale(currentTemp)

  // Rect element for the red mercury column
  svg
    .append("rect")
    .attr("id", "mercuryBar")
    .attr("x", width / 2 - (tubeWidth - 10) / 2)
    .attr("y", tubeFill_top)
    .attr("width", tubeWidth - 10)
    .attr("height", tubeFill_bottom - tubeFill_top)
    .style("shape-rendering", "crispEdges")
    .style("fill", mercuryColor)

  // Main thermometer bulb fill
  svg
    .append("circle")
    .attr("r", bulbRadius - 6)
    .attr("cx", bulb_cx)
    .attr("cy", bulb_cy)
    .style("fill", "url(#bulbGradient)")
    .style("stroke", mercuryColor)
    .style("stroke-width", "2px")

  // Values to use along the scale ticks up the thermometer
  let tickValues = d3
    .range((domain[1] - domain[0]) / step + 1)
    .map(function (v) {
      return domain[0] + v * step
    })

  // D3 axis object for the temperature scale
  let axis = d3
    .axisLeft(scale)
    .tickSizeInner(7)
    .tickSizeOuter(0)
    .tickValues(tickValues)

  // Add the axis to the image
  let svgAxis = svg
    .append("g")
    .attr("id", "tempScale")
    .attr("transform", "translate(" + (width / 2 - tubeWidth / 2) + ",0)")
    .call(axis)

  // Format text labels
  svgAxis
    .selectAll(".tick text")
    .style("fill", "#777777")
    .style("font-size", "10px")

  // Set main axis line to no stroke or fill
  svgAxis.select("path").style("stroke", "none").style("fill", "none")

  // Set the style of the ticks
  svgAxis
    .selectAll(".tick line")
    .style("stroke", tubeBorderColor)
    .style("shape-rendering", "crispEdges")
    .style("stroke-width", "1px")
}

export const updateValues = (min, max, current) => {
  minTemp = min
  maxTemp = max
  currentTemp = current

  // Determine a suitable range of the temperature scale
  domain = [step * Math.floor(minTemp / step), step * Math.ceil(maxTemp / step)]

  if (minTemp - domain[0] < 0.66 * step) domain[0] -= step

  if (domain[1] - maxTemp < 0.66 * step) domain[1] += step

  // D3 scale object
  scale = d3
    .scaleLinear()
    .range([bulb_cy - bulbRadius / 2 - 8.5, top_cy])
    .domain(domain)

  // Max and min temperature lines
  ;[minTemp, maxTemp].forEach(function (t) {
    let isMax = t === maxTemp,
      label = isMax ? "max" : "min",
      textOffset = isMax ? -4 : 4

    d3.select(`#${label}Line`).attr("y1", scale(t)).attr("y2", scale(t))

    d3.select(`#${label}Label`)
      .attr("y", scale(t) + textOffset)
      .attr("dy", isMax ? null : "0.75em")
      .text(`${label} ${t}`)
  })

  let tubeFill_bottom = bulb_cy,
    tubeFill_top = scale(currentTemp)

  // Rect element for the red mercury column
  d3.select("#mercuryBar")
    .attr("y", tubeFill_top)
    .attr("height", tubeFill_bottom - tubeFill_top)

  // Values to use along the scale ticks up the thermometer
  let tickValues = d3
    .range((domain[1] - domain[0]) / step + 1)
    .map(function (v) {
      return domain[0] + v * step
    })

  // D3 axis object for the temperature scale
  let axis = d3
    .axisLeft(scale)
    .tickSizeInner(7)
    .tickSizeOuter(0)
    .tickValues(tickValues)

  // Add the axis to the image
  d3.select("#tempScale").call(axis)

  /*// Format text labels
  svgAxis
    .selectAll(".tick text")
    .style("fill", "#777777")
    .style("font-size", "10px");

  // Set main axis line to no stroke or fill
  svgAxis
    .select("path")
    .style("stroke", "none")
    .style("fill", "none");

  // Set the style of the ticks
  svgAxis
    .selectAll(".tick line")
    .style("stroke", tubeBorderColor)
    .style("shape-rendering", "crispEdges")
    .style("stroke-width", "1px");*/
}
