import React from "react"

import modalsStyles from "./modals.module.scss"
import webcamStyles from "../components/webcam.module.scss"

const WebcamModal = ({ id, imageSrc }) => {
  return (
    <div
      id={id}
      className="modal fade"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="Webcam"
      aria-hidden="true"
    >
      <div
        className={`modal-dialog modal-dialog-centered ${modalsStyles.modalHuge}`}
      >
        <div className="modal-body text-center">
          <img
            src={imageSrc}
            className={webcamStyles.webcamImage}
            alt="Webcam TaladaMeteo"
            title="Immagine aggiornata ogni 30 minuti"
          />
        </div>
      </div>
    </div>
  )
}

export default WebcamModal
